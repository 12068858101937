import React from "react"
import PMPDealForm from "./pmp-deal-form"

export default function CTASection() {
  return (
    <section className="background-blue-light">
      <div className="container py-5 px-3">
        <div className="columns is-vcentered pt-2">
          <div className="column mt-4" style={{ alignSelf: "flex-start" }}>
            <h1
              className="text-dark-blue is-size-1-desktop is-size-3-mobile has-text-weight-semibold"
              style={{ lineHeight: "1.1" }}
            >
              <span>Ready to Activate</span> <br />
              <span>AI Optimization?</span>
            </h1>
          </div>
          <div className="column">
            <PMPDealForm
              theme="light"
              formPlacement="Landing Page PMP - Bottom"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
